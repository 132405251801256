import React, { useEffect } from 'react';
import { styled, theme } from '../Theme';
import { media } from '@glitz/core';
import Translate from '../Shared/Common/Translate';
import { useUserStateData } from '../Shared/UserContextProvider/UserContextProvider';
import useCurrentPage from '../Shared/Hooks/useCurrentPage';
import AccountPageModel from './Models/AccountPageModel.interface';
import ContentArea from '../Shared/ContentArea/ContentArea';
import { useAppSettingsData } from '../Shared/AppSettingsProvider/AppSettingsProvider';
import XhtmlComponent from '../Shared/XhtmlComponent/XhtmlComponent';
import Grid from '../Shared/Grid/Grid';
import { applyEditModeAttr } from '../Shared/Common/Helpers';
import { GridSize } from '../Enums/GridSize.enum';
import { useKexNavigate } from '../Shared/KexRouter/KexRouter';

function AccountPage() {
  const { fullName } = useUserStateData();
  const kexNavigate = useKexNavigate();

  const {
    translations: { 'account/greetingheader': greetingheaderLabel },
    siteRoute,
  } = useAppSettingsData();

  const {
    contentArea,
    introText,
    inEditMode,
    unauthorizedRedirectUrl,
  } = useCurrentPage<AccountPageModel>();

  useEffect(() => {
    if (unauthorizedRedirectUrl) {
      if (unauthorizedRedirectUrl === '') kexNavigate(`/${siteRoute}`);
      else kexNavigate(`${unauthorizedRedirectUrl}`);
    }
  }, [unauthorizedRedirectUrl, siteRoute, kexNavigate]);

  return (
    <Container>
      <RightContainer>
        <TextWrapper>
          <Translate
            translation={greetingheaderLabel}
            values={[fullName]}
            component={Heading}
          />
          <XhtmlComponent content={introText} />
        </TextWrapper>
        <Grid
          type={GridSize.ContentContainer}
          data-content-area
          {...applyEditModeAttr(inEditMode && 'MainContent')}
        >
          {Array.isArray(contentArea) && (
            <ContentArea childItems={contentArea} />
          )}
        </Grid>
      </RightContainer>
    </Container>
  );
}

export default AccountPage;

const Container = styled.div({
  display: 'grid',
  gridGap: theme.spacing(4),
  gridTemplateColumns: '1fr',
  width: '100%',
  height: '100%',
  minHeight: '95vh',
  justifyContent: 'center',
  gridRowGap: theme.none,
  margin: {
    x: 'auto',
  },
  color: theme.black,
  maxWidth: theme.contentMaxWidth,
});

const RightContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
});

const Heading = styled.h2({
  font: { size: theme.epsilon, weight: theme.fontWeight.bold },
  marginBottom: theme.spacing(4),
  ...media(theme.mediaQuery.mediaMinLarge, {
    fontSize: theme.rho,
    marginBottom: theme.spacing(4),
  }),
});

const TextWrapper = styled.div({
  margin: { x: theme.spacing(4), bottom: theme.spacing(8) },
  ...media(theme.mediaQuery.mediaMinLarge, {
    margin: { x: theme.none, bottom: theme.spacing(6) },
  }),
});
